.App {
    text-align: center;
}

* {
    background-color: #000000;
    color: #c9d1d9;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.top20 {
    padding-top: 20px;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
}

.down {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.up {
    width: 12px;
    height: 12px;
    background-color: #0cb00c;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.unhealthy {
    width: 12px;
    height: 12px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}